<template>
  <index-view
    view-name="people"
    :module="module"
    :model="model"
    :list="list"
    :show-resource-route-name="'Person'"
    :pagination="pagination"
    :sort="sort"
    :view-config="viewConfig"
    row-pointer
    :fetch="fetch"
    :filters-cache="filtersCache"
    :set-filters-cache-key="setFiltersCacheKey"
    :reset-filters-cache="resetFiltersCache"
  />
</template>

<script>
import indexView from '@/global/components/Index'
import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapActions } = createNamespacedHelpers('people/people')
export default {
  name: 'PeopleIndexView',
  components: {
    'index-view': indexView
  },
  props: {
    module: {
      type: String,
      default: 'people'
    },
    model: {
      type: String,
      default: 'people'
    }
  },
  computed: {
    ...mapGetters([
      'list',
      'pagination',
      'sort',
      'filtersCache',
      'viewConfig'
    ])
  },
  methods: {
    ...mapActions([
      'fetch',
      'setFiltersCacheKey',
      'resetFiltersCache'
    ])
  }
}
</script>
